.ant-picker-range .ant-picker-clear {
  font-size: 16px; /* Đặt kích thước font mong muốn */
}

.sapp-date-placeholder .ant-picker-input:nth-child(1) {
  width: 50%;
}

.sapp-date-value .ant-picker-input:nth-child(1) {
  width: 65%;
}

.ant-picker .ant-picker-input > input {
  min-width: inherit !important;
}

.ant-btn-variant-solid {
  background: var(--bs-primary);

  &:hover {
    background: var(--bs-primary);
  }
}

.ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):hover {
  background: var(--bs-primary-active);
}

// .sapp-h-datimepicker.sapp-date-placeholder .ant-picker-input:nth-child(1) {
//   width: 55%;
// }
