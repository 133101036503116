.lessonFormTree {
  .ant-tree-treenode {
    line-height: 40px;
  }

  .ant-tree-switcher {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-tree-switcher:before {
    top: 50%;
    transform: translateY(-50%);
  }

  .ant-tree-checkbox:not(.ant-tree-checkbox-disabled) {
    &:hover {
      .ant-tree-checkbox-inner {
        border-color: var(--bs-primary) !important;
      }
    }
  }

  .ant-tree-checkbox-checked {
    .ant-tree-checkbox-inner {
      background-color: var(--bs-primary) !important;
      border-color: var(--bs-primary) !important;

      &::after {
        top: 45% !important;
        width: 8px !important;
        height: 12px !important;
      }
    }
  }

  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
    &::after {
      background-color: var(--bs-primary) !important;
    }
  }

  .ant-tree-checkbox-inner {
    width: 24px !important;
    height: 24px !important;
  }
}

.error-message {
  position: absolute;
  bottom: 0px;
  color: var(--bs-danger);
}
