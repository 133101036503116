@import '/src/_metronic/assets/sass/init';
.collapse {
  :global(.ant-collapse-header) {
    border-bottom: 1px dashed var(--Input-Border, #dbdfe9);
    padding: 12px 0 !important;
    margin: 0 16px 0 0;
  }

  :global(.ant-collapse-content-box) {
    padding: 0 16px !important;
  }
}

.permissionInfoContainer {
  margin-left: 12px;
}

.workflow {
  &:hover {
    text-decoration: underline !important;
  }
}
