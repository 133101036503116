.drawer-repeat-checkbox-label {
  background-color: #f9f9f9;
  color: #99a1b7;
}

.drawer-repeat-checkbox-label.checked {
  color: white;
  background-color: var(--bs-primary);
  font-weight: bold;
}

.drawer-repeat-checkbox-label.disabled {
  background-color: #dadfe5;
  color: #374151;
}
