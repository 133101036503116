@import '../_metronic/assets/sass/init';
@import '../components/user-list/components/user-edit-modal/UserOtp.scss';
@import './layout/grid';

:root {
  --bs-primary-border: #99a1b7;
  --bs-border: #dbdfe9;
  --bs-danger: #f14c41;
  --text-danger: #f8285a;
  --text-primary-green: #65d397;
  --text-primary-black: #050505;
  --primary-yellow: #f6c000;
}
.sapp-border-gray_ECECEC {
  border-color: #ececec !important;
}

.sapp-mw-1200px {
  max-width: 1200px;
}

.sapp-mw-1144px {
  max-width: 1144px;
}

.sapp-w-025 {
  width: calc(100% / 5);
}

@media (min-width: 1200px) {
  .sapp-logo {
    width: 225px !important;
    height: 125px !important;
  }

  .sapp-p--layout {
    padding-top: 80px !important;
  }
}

@media (min-width: 992px) {
  .sapp-me-lg-n28px {
    margin-right: -28px !important;
  }
}

.sapp-flex-1 {
  flex: 1;
}

.sapp-w-fit-content {
  width: fit-content;
}

.sapp-cursor-pointer {
  cursor: pointer;
}

.ant-select-show-arrow {
  border-radius: $progress-border-radius !important;
}

.sapp-text--pagination {
  color: var(--bs-primary-border);
}

.ant-pagination-item,
.sapp-text--pagination {
  font-size: $app-general-root-font-size-desktop !important;
  font-weight: $font-weight-semibold !important;
}

.ck-content {
  min-height: fit-content;
}

.btn-sapp-filter {
  background-color: var(--bs-secondary-light) !important;
  color: $gray-900 !important;
}

.btn.btn-sapp-filter i {
  color: $gray-600 !important;
}

.btn-sapp-icon {
  background-color: #f2f2f2 !important;
}

.card-footer {
  display: flex !important;
  justify-content: end !important;
  color: #78829d !important;
}

.sapp-link-primary {
  color: #3699ff !important;
}

.sapp-link-primary:hover {
  color: #037efa !important;
}

.ant-tooltip-inner {
  color: #000000 !important;
}

.ant-picker-dropdown {
  z-index: 9999;
}

.ant-popover-inner {
  padding: 0 !important;
  margin-left: -0.3rem !important;
  margin-top: -0.3rem !important;
}

.sapp-menu-sub-dropdown {
  border-radius: 0.65rem;
  background-color: var(--bs-menu-dropdown-bg-color);
  z-index: 107;
}

.ant-drawer-body {
  padding: 0 !important;
}

.ant-pagination-item-active {
  color: #fff;
}

.sapp-bg-sidebar {
  background-color: var(--bs-app-sidebar-primary-base-bg-color) !important;
}

.w-sm-300px {
  width: 300px !important;
}

.sapp-height-min--content {
  height: 40px;
}

.ant-pagination-item-active {
  border-color: var(--bs-primary) !important;
  background-color: var(--bs-primary) !important;
}

.anticon {
  vertical-align: 0 !important;
}

.color-title--menu {
  color: #000000e0;
}

.sapp-height-layout--login {
  height: 100vh;
}

// .ant-skeleton-image {
//   height: 170px !important;
// }

.sapp-pt-7\.5 {
  padding-top: 1.84615rem !important;
}

.sapp-ps-7\.5 {
  padding-left: 1.84615rem !important;
}

.sapp-pe-7\.5 {
  padding-right: 1.84615rem !important;
}

.sapp-pb-7\.5 {
  padding-bottom: 1.84615rem !important;
}

.mw-900px {
  max-width: 900px !important;
}

.ant-upload-list-item-name,
.anticon-paper-clip,
.anticon-delete {
  color: #000000 !important;
}

.ant-upload-icon {
  display: none;
}

.swal2-icon-content {
  font-size: 3.75em;
}

.swal2-icon {
  position: relative;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  border: 0.25em solid transparent;
  border-radius: 50%;
  line-height: 5em;
  cursor: default;
  box-sizing: content-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  zoom: normal;
}

.ant-picker:hover {
  border-color: #e1e3ea;
}

@media only screen and (max-width: 600px) {
  .card .card-header .card-title {
    margin: 0;
  }
}

.device-header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  min-height: 90px;
  padding: 0 $card-px;
  color: var(--#{$prefix}card-cap-color);
  background-color: var(--#{$prefix}card-cap-bg);
  border-bottom: $card-border-width $card-border-style var(--#{$prefix}card-border-color);
}

.sapp-custom-background-layout-2 {
  left: 0 !important;
}

.custom-background-color {
  background-color: var(--bs-white) !important;
}

.sapp-custom-background-icon {
  // background-color: white !important;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.03) !important;
}

body {
  background-color: var(--bs-gray-100) !important;
  // overflow-y: scroll !important;
}

.ant-upload-drag:hover {
  border-color: var(--bs-primary) !important;
}

.sapp-height-list--grouping {
  height: min-content;
}

.sapp-pagination .ant-pagination-options {
  display: none !important;
}

.sapp-custom-container {
  max-width: calc(1320px + 2.5rem);
  margin: 0 auto;
  box-sizing: border-box;
}

.min-w-55px {
  min-width: 55px !important;
}

.sapp-w-front--face {
  width: 370px !important;
}

.sapp-h-front--face {
  height: 220px !important;
}

@media (max-width: 575px) {
  .sapp-w-front--face {
    width: 280px !important;
  }
}

@media (min-width: 575px) and (max-width: 1000px) {
  .sapp-w-front--face {
    width: 330px !important;
  }

  .sapp-h-front--face {
    height: 220px !important;
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .sapp-w-front--face {
    width: 320px !important;
  }

  .sapp-h-front--face {
    height: 190px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1280px) {
  .sapp-w-front--face {
    width: 245px !important;
  }

  .sapp-h-front--face {
    height: 150px !important;
  }
}

@media (min-width: 1280px) and (max-width: 1400px) {
  .sapp-w-front--face {
    width: 270px !important;
  }

  .sapp-h-front--face {
    height: 180px !important;
  }
}

@media (min-width: 1405px) and (max-width: 1580px) {
  .sapp-w-front--face {
    width: 310px !important;
  }

  .sapp-h-front--face {
    height: 200px !important;
  }
}

.w-35px {
  width: 35px !important;
}

@media (min-width: 768px) and (max-width: 800px) {
  .space-sapp-action--button {
    padding-right: 1rem !important;
  }
}

div:where(.swal2-icon) .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 3.75em;
}

.sapp-p-content-modal {
  padding: 2rem 1.75rem;
}

.swal2-container .swal2-html-container {
  max-height: 200px;
  overflow: auto;
}

.swal2-html-container {
  font-weight: 400;
  font-size: 1.1rem;
  margin-top: 1.5rem;
  color: var(--bs-gray-800);
}

div:where(.swal2-container) .swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 1em 1.6em 0.3em;
  padding: 0;
  overflow: auto;
  color: inherit;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
}

.swal2-actions {
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 1.25em auto 0;
  z-index: 1;
}

.mw-430px {
  max-width: 430px;
}

.swal2-actions .btn {
  margin: 15px 5px 0;
}

div:where(.swal2-container) div:where(.swal2-actions) {
  display: flex;
  z-index: 1;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 1.25em auto 0;
  padding: 0;
}

.swal2-icon.swal2-success {
  border-color: #a5dc86;
}

.swal2-icon.swal2-success [class^='swal2-success-circular-line'][class$='left'] {
  top: -0.4375em;
  left: -2.0635em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 3.75em 3.75em;
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
}

.swal2-icon.swal2-success [class^='swal2-success-line'] {
  display: block;
  position: absolute;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #a5dc86;
  z-index: 2;
}

.swal2-icon.swal2-success [class^='swal2-success-line'] {
  display: block;
  position: absolute;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #a5dc86;
  z-index: 2;
}

.swal2-icon.swal2-success [class^='swal2-success-line'][class$='tip'] {
  top: 2.875em;
  left: 0.875em;
  width: 1.5625em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  top: -0.25em;
  left: -0.25em;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(165, 220, 134, 0.3);
  border-radius: 50%;
  z-index: 2;
  box-sizing: content-box;
}

.swal2-icon.swal2-success .swal2-success-fix {
  position: absolute;
  top: 0.5em;
  left: 1.625em;
  width: 0.4375em;
  height: 5.625em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  z-index: 1;
}

.swal2-icon.swal2-success [class^='swal2-success-circular-line'] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 50%;
}

.swal2-icon.swal2-success [class^='swal2-success-circular-line'][class$='right'] {
  top: -0.6875em;
  left: 1.875em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 3.75em;
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0;
}

.swal2-icon.swal2-success [class^='swal2-success-line'][class$='long'] {
  top: 2.375em;
  right: 0.5em;
  width: 2.9375em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.text-capitalize-first::first-letter {
  text-transform: uppercase;
}

.sapp-p-button--custom {
  padding: 0.7rem 1.5rem !important;
  height: fit-content;
}

.sapp-text-truncate-3 {
  max-width: 250px;
  width: 100%;
  max-height: 100%;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sapp-text-truncate-1 {
  width: 100%;
  max-height: 100%;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sapp-text-truncate-2 {
  width: 100%;
  max-height: 100%;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Inter;
}

.sapp-table-sticky-left {
  position: sticky;
  left: -2.25rem;
  background-color: grey;
}

.sapp-table-separate-0 {
  border-collapse: separate;
  border-spacing: 0;
}

.sapp-m-h-565px {
  max-height: 565px;
}

// .sapp-m-h-450px {
//   max-height: 450px;
// }

.sapp-overflow-y-layout {
  overflow-y: initial !important;
}

.sapp-h-datimepicker {
  height: 3.1rem !important;
}

.sapp-h-datimerangepicker {
  height: 3.1rem !important;
  width: 90% !important;
}

.swal2-icon.swal2-warning {
  border-color: var(--bs-warning);
  color: var(--bs-warning);
}

.swal2-popup .swal2-actions {
  margin: 1.5rem auto 1rem auto;
}

.sapp-custom-wh-380px {
  width: 88vh !important;
  height: 68vh !important;
}

.sapp-menu-sub-dropdown
  .menu-item.hover:not(.here)
  > .menu-link:not(.disabled):not(.active):not(.here),
.sapp-menu-sub-dropdown
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled):not(.active):not(.here) {
  background-color: var(--bs-menu-link-bg-color-hover) !important;
}

.sapp-bg-active-popover {
  background-color: var(--bs-menu-link-bg-color-hover) !important;
}

.sapp-text-active-popover {
  color: var(--bs-primary) !important;
}

table {
  width: 100%;

  .sapp-absolute-column:last-child,
  .sapp-absolute-column:last-child {
    position: sticky;
    right: -15px;
    background: white; /* Optional: to ensure it doesn't overlap content */
    z-index: 2;
    background-color: white; // Adjust as needed
  }
  .sapp-classes-column:last-child {
    position: relative;
    right: 0;
  }
}

.sapp-mw-450px {
  max-width: 450px !important;
}

.sapp-btn-color {
  color: var(--bs-light-inverse);
  background-color: var(--bs-light);
}

.sapp-min-w-100px {
  min-width: 100px;
}

.min-w-90px {
  min-width: 90px;
}

.min-w-70px {
  min-width: 70px !important;
}

.sapp-w-60px {
  width: 4.4rem !important;
}

.form-control:disabled,
.sapp-hook-form-tag-disabled {
  background-color: #f5f5f5 !important;
}

.sapp-h-40 {
  height: 40px !important;
}

.sapp-h-48px {
  height: 48px !important;
}

.sapp-w-49 {
  width: 49%;
}

.sapp-text-primary {
  color: var(--bs-gray-800) !important;
  font-size: 14px !important;
}

@media (min-width: 1300px) {
  .sapp-mw-425px {
    max-width: 425px !important;
  }

  .sapp-modal-dialog {
    max-width: 40% !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .sapp-modal-dialog {
    max-width: 80% !important;
  }
}

.sapp-p-body-content {
  padding-right: 0 !important;
}

.sapp-overflow-y {
  overflow-y: hidden !important;
}

.sapp-image-input-wrapper {
  background-size: contain !important;
}

// fix z-index modal, Khai báo biến cho số lượng phần tử và bước nhảy của z-index
$elements: 30;
$step: 1;

body {
  // Vòng lặp for từ 1 đến số lượng phần tử
  @for $i from 1 through $elements {
    // Tính toán giá trị z-index cho mỗi phần tử
    $z-index: 1125 - ($elements - $i + 1 + ($i - 1) * $step);

    // Chọn phần tử thứ i từ cuối lên đầu
    & > div:not(#root):nth-last-child(#{$i}) {
      // Gán giá trị z-index cho phần tử
      z-index: $z-index;
    }
  }
}

body:not(:has(.modal, .ant-drawer, .tox-dialog-wrap, .tox-fullscreen)) {
  .app-header {
    z-index: 3000 !important;
  }
}

body:has(.modal, .ant-drawer, .tox-dialog-wrap, .tox-fullscreen) {
  .app-header {
    z-index: 1 !important;
  }
}

.table-row-underline {
  border-bottom-width: 1px !important;
  border-bottom-style: dashed !important;
  border-bottom-color: #e1e3ea !important;
}

.sapp-fs-20 {
  font-size: 20px;
}

.sapp-fs-18 {
  font-size: 18px;
}

.sapp-fs-14 {
  font-size: 14px;
}

.sapp-fs-16 {
  font-size: 16px;
}

.sapp-fs-2\.2rem {
  font-size: 2.2rem;
}

.sapp-custom-layout {
  margin-left: 0 !important;
  margin-top: 1.5rem !important;
}

.sapp-ellipsis-text {
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sapp-min-width-fit {
  min-width: fit-content;
}

.sapp-form-check-label {
  font-family: Inter;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 14px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.sapp-text-drawer--description {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #a1a5b7;
}

.sapp-me-0 {
  margin-right: 0px !important;
}

.line-Height {
  line-height: 12px !important;
}

.sapp-title--success {
  // font-family: Roboto;
  font-size: 36px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
}

.sapp-title-device {
  // font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
}

.sapp-title-button {
  // font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  height: 50px;
}

.sapp-body--success {
  // font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #a1a1a1;
}

.sapp-h-90px {
  height: 90px !important;
}

.sapp-drawer--custom .ant-drawer-mask {
  background: rgba(0, 0, 0, 0.1) !important;
}

.sapp-w-15 {
  width: 15% !important;
}

.sapp-text--default {
  color: #141414 !important;
}

.sapp-fs-16px {
  font-size: 16px !important;
}

.sapp-fs .form-control,
.sapp-fs--default {
  font-size: 13px !important;
}

.sapp-checkbox--icon.form-check-input {
  width: 24px !important;
  height: 24px !important;
}

.sapp-fs .ant-select-selector .ant-select-selection-placeholder {
  font-size: 13px !important;
}

.sapp-text-light-inverse {
  color: var(--bs-light-inverse) !important;
}

.sapp-form-control {
  color: var(--bs-gray-800) !important;
  line-height: 14px !important;
  font-size: 14px !important;
  border-radius: 6px !important;
  font-family: Inter, Helvetica, 'sans-serif' !important;
}

.sapp-line-height-16 {
  line-height: 16px;
}

.sapp-py-14-5 {
  padding-top: 14.5px !important;
  padding-bottom: 14.5px !important;
}

.sapp-shadow-none {
  box-shadow: none;
}

.ant-tooltip {
  font-family: Inter !important;
}

.table-responsive table thead tr th.sapp-text-header--column {
  color: var(--bs-primary-border);
  font-size: 12px;
  line-height: 12px;
  vertical-align: middle;
}

.sapp-label {
  font-size: 20px;
  font-weight: 600;
  color: var(--bs-gray-800);
  margin-bottom: 32px;
  line-height: 21px;
}

.sapp-line--height {
  line-height: 21px;
}

.sapp-mb-10px {
  margin-bottom: 10px;
}

.sapp-participant-label {
  color: var(--Gray, var(--bs-primary-border));
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.card-header.pt-5 {
  min-height: max-content;
}

.sapp-mb-24px {
  margin-bottom: 24px;
}

// .card-header.border-0.pt-6 {
//   padding-left: 32px;
//   padding-right: 32px;
// }

.card-header.border-0.pt-10 {
  padding-left: 32px;
  padding-right: 32px;
}

.border-0.align-items-center.sapp-h-80px {
  height: 80px;
}

.card-body .pr-\[6px\] {
  padding-right: 6px;
}

.editor-wrap {
  font-family: Roboto, sans-serif;
  color: #5e6278;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  appearance: none;

  img.Wirisformula {
    width: auto !important;
  }

  img:not([id]) {
    width: 100%;
    height: auto;
  }

  math {
    mrow {
      font-size: inherit;
      padding: 6px 0px;
    }

    mo.empty-mo {
      width: 0px;
      margin: 0 -2px;
    }

    mi[mathvariant='bold-italic'] {
      font-weight: bold;
    }
  }
}

* {
  &::-webkit-scrollbar {
    height: 10px;
    width: 5px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--bs-primary);
  }
}

/* Mac */
body *::-webkit-scrollbar {
  width: 17px;
}

body *::-webkit-scrollbar-track {
  background-color: transparent;
}

body *::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

body *::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.sapp-min-h-100vh {
  min-height: 100vh !important;
}

.sapp-min-h-80vh {
  min-height: 80vh !important;
}

.sapp-w-175px {
  width: 175px !important;
}

.sapp-text-danger {
  color: var(--text-danger);
}

.sapp-custom-background-layout {
  background-color: #f9f9f9 !important;
}

.sapp-h-35px {
  height: 35px !important;
}

.sapp-margin-left-10 {
  margin-left: 10px !important;
}

.text-primary-green {
  color: var(--text-primary-green) !important;
}

.sapp-max-w-105px {
  max-width: 105px !important;
}

.sapp-bg-gray-200 {
  background-color: #f4f4f4;
}

.ant-tooltip-inner {
  min-width: fit-content !important;
}

.sapp-w-90 {
  width: 90%;
}

.sapp-border {
  border: 1px solid var(--bs-gray-300);
}

.sapp-max-w-105px {
  max-width: 105px !important;
}

.dashed-border-bottom {
  border-bottom: 2px dashed transparent;
  border-image: repeating-linear-gradient(
      90deg,
      var(--bs-gray-300),
      var(--bs-gray-300) 10px,
      transparent 10px,
      transparent 20px
    )
    1;
}

.sapp-mw-700px {
  max-width: 700px;
}

.sapp-min-w-210px {
  min-width: 210px !important;
}

.card {
  box-shadow: none;
}

.sapp-text {
  &-primary-black {
    color: var(--text-primary-black);
  }
}

.sapp-font-lms {
  font-family: Roboto, sans-serif;
}

#msdoc-renderer,
#proxy-renderer {
  height: 100%;
}

@media (min-width: 1400px) {
  .app-container.container-xxl {
    max-width: 1440px;
  }
}

.ant-table-cell .text-success {
  color: #397839 !important;
}

.min-h-300px {
  min-height: 300px;
}
