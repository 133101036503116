@import '/src/_metronic/assets/sass/init';

.viewIcon {
  display: flex;
  margin-right: 10px;
}

.buttonIcon {
  height: 30px !important;
}

.empty {
  height: 50px;
  display: grid;
  place-items: center;
  font-weight: 600;
  color: $gray-600;
}
