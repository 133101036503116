.departmentTable {
  border: 1px solid var(--Input-Border, #dbdfe9);
  border-radius: 6px;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);

  :global(.ant-table-thead) {
    font-size: 12px;
  }

  td {
    border-bottom: none !important;
  }

  .btn.btn-icon {
    height: 21px;
  }
}

.departmentName {
  display: inline-block;
  max-width: 70%;
  white-space: nowrap; /* Prevent the text from wrapping */
  overflow: hidden; /* Hide the overflowing content */
  text-overflow: ellipsis; /* Display an ellipsis (...) for overflowing text */
  vertical-align: middle;
}

.firstRow {
  background-color: #f9f9f9;

  :global(.ant-table-cell-fix-right) {
    background-color: #f9f9f9;
  }
}

.row {
  height: 21px;
}

.firstCol {
  font-weight: 500;
  color: #252f4a;
  display: flex;
  flex-direction: row;
}

.noOfEmployeeCol {
  color: #99a1b7;
  margin: 0;
}

.actionCell {
  height: 21px;
}
