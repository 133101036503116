.collapse {
  background-color: white;
  border-color: var(--bs-gray-200);

  :global(.ant-collapse-item) {
    margin: 0 28px;
  }

  :global(.ant-collapse-header) {
    background-color: white;
    border-color: var(--bs-gray-200);
    padding: 16px 0 !important;
  }

  :global(.ant-collapse-content) {
    border-top: 1px solid var(--bs-gray-200);
    padding: 16px 0 !important;
  }

  :global(.ant-collapse-content-box) {
    padding: 0 !important;
  }
}
