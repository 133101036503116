@import '/src/styles/index.scss';

.job_position {
  &:not(:last-child) {
    border-bottom: 1px dashed $input-border;
    padding-bottom: 24px;
    margin-bottom: 12px;
  }
}

.trashContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.trashFiller {
  height: 21px;
  margin-bottom: 10px;
}

.trashIcon {
  max-height: 40px;
  flex-grow: 1;
}
