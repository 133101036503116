.truncate-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.tooltip-box div span p {
  margin-left: 8px;
  margin-bottom: 0px;
}

.close {
  z-index: 10;
}

.bar-container.tooltip {
  opacity: 1;
}
