.sapp-calendar-picker {
  background-color: #f897070d !important;
  border-color: #f89707 !important;
}

.sapp-wawrning-text {
  color: #78829d;
}

.h-60px {
  height: 60px;
}

.event-info-table th {
  width: 30%;
  color: #6c757d;
  font-weight: 600;
  vertical-align: middle;
}

.event-info-table td {
  color: #212529;
  font-weight: 500;
}

.event-info-table td > .badge {
  padding: 0.4rem 0.6rem;
  font-size: 0.85rem;
  font-weight: 600;
}

.event-info-table td > a {
  text-decoration: none;
  font-weight: bold;
}

.event-info-table td > a:hover {
  text-decoration: underline;
}

.event-info-table > tbody > tr {
  height: 60px !important;
  border-bottom: 1px dashed #dbdfe9 !important;
}

.event-info-table > tbody > tr > th {
  color: #99a1b7 !important;
}

.event-info-table > tbody > tr:last-child {
  border-bottom: none;
}

.sapp-border-info {
  border-bottom: 1px dashed #dbdfe9 !important;
}

.badge-info {
  background-color: #176cdd0d;
  color: #176cdd;
  padding: 0 5px;
}

.badge-warning {
  background-color: #f897070d;
  color: #f89707;
  padding: 0 5px;
}

.badge-success {
  background-color: #07af170d;
  color: #07af17;
  padding: 0 5px;
}

.badge-danger {
  background-color: #ff5b5b0d;
  color: #ff5b5b;
  padding: 0 5px;
}
