.create-class {
  .btn-delete-schedule {
    &:hover {
      color: var(--bs-primary); // Change color for both icon & text

      svg,
      span {
        color: var(--bs-primary); // Make sure children inherit the hover color
      }
    }
  }

  .btn-add-schedule {
    .ki-outline {
      color: inherit;
    }

    &:hover {
      color: var(--bs-primary-active); // Change color for both icon & text

      .ki-outline {
        color: var(--bs-primary-active); // Make sure children inherit the hover color
      }
    }
  }
}
