.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected > .ant-table-cell {
  background-color: #fffaf0 !important;
}

.ant-spin .ant-spin-dot-holder {
  color: var(--bs-primary) !important;
}

.ant-pagination .ant-pagination-options-quick-jumper input:hover {
  border-color: var(--bs-primary) !important;
}

.ant-pagination .ant-pagination-options-quick-jumper input:focus {
  border-color: var(--bs-primary) !important;
  box-shadow: none !important;
}

.ant-table-wrapper .ant-table,
.ant-select,
.ant-pagination .ant-pagination-item,
.ant-pagination {
  font-family: Inter !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
  border-color: var(--bs-primary) !important;
}

// Bao bọc trong selector của component để tránh xung đột toàn cục
.sapp-table {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: var(--bs-primary) !important; // Đổi màu viền thành đỏ khi hover
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: var(--bs-primary) !important; // Màu viền khi được chọn
  }

  .ant-checkbox-checked::after {
    border-color: var(--bs-primary) !important; // Màu viền hiệu ứng focus/hover
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
  }

  .ant-radio-wrapper {
    &:hover {
      .ant-radio-inner {
        border-color: var(--bs-primary);
      }
    }
  }
}

// Thêm hiệu ứng hover
.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon:hover,
.ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon:hover {
  .anticon {
    color: var(--bs-primary) !important;
  }
}
