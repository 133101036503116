.lesson-list__tree {
  .ant-tree-node-content-wrapper {
    // width: 100%;
    position: relative;
    border: 1px solid var(--bs-gray-200);
    border-left: none;
    border-radius: 0 8px 8px 0;
    transition: none;
  }

  .ant-tree-switcher {
    margin-inline-end: 0;
    position: relative;
    border: 1px solid var(--bs-gray-200);
    border-right: none;
    border-radius: 8px 0px 0px 8px;
    transition: none;
    width: 56px;
    padding-right: 12px;
    text-align: right;
    display: flex !important;
    justify-content: center;
    align-items: center;

    &::before {
      content: none;
    }

    &:hover {
      + .ant-tree-node-content-wrapper {
        background-color: #fffdf6;
        border-color: var(--bs-primary-border);
      }
    }
  }

  .ant-tree-switcher-noop {
    width: 0;
    border: none;
    padding: 0;
  }

  .ant-tree-switcher-noop + .ant-tree-node-content-wrapper {
    border-left: 1px solid var(--bs-gray-200);
    border-radius: 8px;
    padding-left: 20px;
  }

  .ant-tree-treenode {
    width: 100%;
    line-height: 60px;
    margin-bottom: 16px;

    .ant-tree-title {
      font-weight: 500;
    }

    &:hover {
      .ant-tree-title,
      .ant-tree-switcher-icon {
        color: var(--bs-primary);
      }

      .ant-tree-switcher,
      .ant-tree-node-content-wrapper {
        background-color: #fffdf6;
        border-color: var(--primary-border);
      }
    }
  }

  .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line {
    &::before {
      height: 32px !important;
    }
    &::after {
      height: 32px !important;
    }
  }

  .ant-tree-switcher-icon {
    color: var(--bs-gray-1000);
  }

  .ant-tree-switcher_open {
    .ant-tree-switcher-icon {
      color: var(--bs-primary);
    }
  }

  .ant-tree-indent-unit {
    width: 48px;
  }

  .menu-link {
    height: 40px;
  }
}
