.examSchedule {
  padding: 0;
  list-style-type: circle;
}

.scheduleTitle {
  padding-left: 32px;
}

.examStudentListContainer {
  padding-top: 12px;
}
