.createDrawerFooter {
  width: 100%;
  margin-bottom: -25px;
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid var(--bs-card-border-color);
}

.formFooter {
  margin-top: 25px;
}
