.sapp-student-eye,
.sapp-student-eye:hover {
  background-color: #eef6ff !important;
  color: #3964ea !important;
  font-size: 12px;

  @media (max-width: 1200px) {
    margin-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
